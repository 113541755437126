export interface TypeFormResponse {
  answers: Answer[];
  calculated: Calculated;
  hidden: Hidden;
  landed_at: string;
  landing_id: string;
  metadata: Metadata;
  response_id: string;
  response_type: string;
  submitted_at: string;
  token: string;
  variables: Variable[];
}

interface Variable {
  key: string;
  text: string;
  type: string;
}

interface Metadata {
  browser: string;
  network_id: string;
  platform: string;
  referer: string;
  user_agent: string;
}

interface Hidden {
  phone_number: string;
  prefix_phone_number: string;
  user_uuid: string;
}

interface Calculated {
  score: number;
}

interface Answer {
  field: Field;
  text?: string;
  number?: number;
  type: string;
  choice?: Choice;
}

interface Choice {
  id: string;
  label: string;
  ref: string;
}

interface Field {
  id: string;
  ref: string;
  type: string;
}

export function findTypeformResponseValue<T>(value: string, response: TypeFormResponse): T {
  const answer = response.answers.find(answer => answer.field.ref === value);
  if (answer) {
    return (answer.text || answer.number || answer.choice?.label) as unknown as T;
  }
  throw new Error(`Could not find value for ${value}`);
}

export function findTypeformResponseValueSafe<T>(value: string, response: TypeFormResponse): T | undefined {
  try {
    return findTypeformResponseValue<T>(value, response);
  } catch {
    return undefined;
  }
}

export function onlyNumber(value: string | number): number {
  if (typeof value === 'number') {
    return value;
  }

  return +value.replace(/\D/g, '');
}

export function firstNumber(value: string | number): number {
  if (typeof value === 'number') {
    return value;
  }

  const num = value.split(' ').find(v => !isNaN(+v));
  return num ? +num : 0;
}
