import { Component, ElementRef, inject, Inject, OnInit, viewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { createWidget } from '@typeform/embed';
import { forkJoin, switchMap } from 'rxjs';
import { findTypeformResponseValue, findTypeformResponseValueSafe, firstNumber, onlyNumber } from 'src/app/core/interfaces/typeform-response';
import { UiService } from 'src/app/core/ui-service/ui.service';
import { UserService } from 'src/app/core/user-service/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-typeform-dialog',
  standalone: true,
  imports: [],
  templateUrl: './typeform-dialog.component.html',
  styleUrl: './typeform-dialog.component.scss',
})
export class TypeformDialogComponent implements OnInit {
  private typeformContainer = viewChild<ElementRef<HTMLElement>>('typeformContainer');

  private userService = inject(UserService);
  private uiService = inject(UiService);

  private responseId?: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { link: string },
    private dialogRef: MatDialogRef<TypeformDialogComponent>
  ) {}

  ngOnInit(): void {
    const typeformContainer = this.typeformContainer();

    if (typeformContainer) {
      createWidget(this.data.link, {
        container: typeformContainer.nativeElement,
        width: '100%',
        height: '100%',
        // preselect: {
        //   name: localStorage.getItem('utm_name') || '',
        //   surname: localStorage.getItem('utm_surname') || '',
        // },
        onSubmit: e => {
          this.responseId = e.responseId;
        },
        onEndingButtonClick: e => {
          console.log({ endingButtonClick: e });
          this.uiService
            .withSpinner(forkJoin([this.userService.typeformResponse(e.formId, this.responseId!), this.userService.getFuturaUser()]))
            .pipe(
              switchMap(([response, user]) => {
                const name = findTypeformResponseValue<string>('name', response).trim();
                const surname = findTypeformResponseValue<string>('surname', response).trim();

                const hoursPerDay = +firstNumber(findTypeformResponseValueSafe<string>('availability_time', response) || '0');
                const daysPerWeek = +firstNumber(findTypeformResponseValueSafe<string>('availability_day', response) || '0');

                const username = `${name.toLowerCase().split(' ').join('')}_${surname.toLowerCase().split(' ').join('')}`;

                user.content.name = name;
                user.content.surname = surname;
                user.content.nickname = username;

                user.content.image = {
                  provider: 'Futura',
                  url: `https://ui-avatars.com/api/?background=152148&font-size=.6&color=fff&name=${name + ' ' + surname}&bold=True&size=1024`,
                };

                if (hoursPerDay) user.content.platforms![environment.platform].hours_per_day = hoursPerDay;
                if (daysPerWeek) user.content.platforms![environment.platform].days_per_week = daysPerWeek;

                const phone_info = JSON.parse(localStorage.getItem('_phone_info')!) as {
                  countryCode: string;
                  dialCode: string;
                  number: string;
                };

                user.content.phone = phone_info.number;
                user.content.prefix = phone_info.dialCode;
                user.content.countryCode = phone_info.countryCode;

                return this.userService.updateFuturaUser(user);

                // this.dialogRef.close({ name, surname, username });
              })
            )
            .subscribe(() => {
              this.dialogRef.close(true);
            });
        },
      });
    }
  }
}
